import { Card, Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import hamilton from "../assets/hamilton-logo.jpg"
import hamilton1 from "../assets/hamilton1.jpg"
import hamilton2 from "../assets/hamilton2.jpg"
import go from "../assets/go.png";

const AK = () => {
    return (
        <Container>
            <Helmet>
                <title>Купить часы Hamilton в Алматы - Aksessuary.KZ</title>
                <meta name="description" content="часы Hamilton"/>
                <meta name="keywords" content="купить часы Hamilton в Алматы, купить наручные часы Hamilton в Алматы, купить часы Hamilton в Казахстане, 
                    наручные часы Hamilton, Hamilton, часы Hamilton в Алматы, часы Hamilton, Hamilton, женские часы, часы" />
            </Helmet>
            <Card  style={{borderColor: '#1200ba'}}>
                <img src={ hamilton } alt="Купить часы Hamilton в Алматы"  width='35%' style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 15}}/>
                <p style={{textAlign: 'justify', marginLeft: '20px', marginRight: '20px'}}>
                    Новаторство, практичность, точность: уникальным образом соединяя в себе швейцарские стандарты точности и подлинно американский дух, 
                    компания Hamilton создает самобытные универсальные часы для поклонников часового искусства и ценителей стильных творений.< br/>
                    Hamilton гордится своей богатой историей и многочисленными достижениями, которые позволили компании завоевать репутацию всемирно известного престижного бренда.
                </p>
                <p style={{textAlign: 'justify', marginLeft: '20px', marginRight: '20px'}}>
                    На службе вооруженных сил вот уже более 75 лет.<br />
                    1914 г. - В качестве официального поставщика Вооруженных сил США во время Первой мировой войны компания Hamilton экипировала сотни 
                    военнослужащих надежными часами. С этого момента бренд ускорил окончательный переход от производства карманных часов к выпуску более 
                    практичных наручных часов.<br />
                    1942 г. - Компания Hamilton приостановила выпуск гражданской продукции, чтобы сосредоточиться на производстве крупных партий, 
                    поставляемых в годы Второй мировой войны Вооруженным силам США. В период с 1942 по 1945 год компания выпустила в общей сложности 
                    более миллиона экземпляров, включая наручные часы и морские хронометры. За отличное качество продукции командование армии и флота присвоило 
                    Hamilton почетный знак «Е».
                </p>
                <p style={{textAlign: 'justify', marginLeft: '20px', marginRight: '20px'}}>
                    Более века назад авиаторские часы Hamilton использовались пилотами первой американской службы почтовых авиаперевозок, которая выполняла 
                    рейсы между Вашингтоном и Нью-Йорком. С той поры ведет отсчет богатая история производства многофункциональных моделей для авиационных компаний, 
                    и сегодня наши часы можно увидеть на руках пилотов из разных стран мира.<br />
                    Инновации были и остаются одним из главных столпов успешной деятельности Hamilton: в середине XX века часовой бренд представил передовые разработки, 
                    которые навсегда изменили мир часового искусства.<br />
                </p>
                <p style={{textAlign: 'justify', marginLeft: '20px', marginRight: '20px'}}>
                    От синхронизации движения первых поездов в США до изобретения электронных и цифровых часов – компания Hamilton неизменно находится в 
                    авангарде часового производства с 1892 года. В начале нового десятилетия мы продолжаем сохранять верность уникальной философии и первопроходческому духу, 
                    которые позволили нам надежно закрепить за собой лидирующие позиции в часовой промышленности.<br />
                    В последние 90 лет за Hamilton прочно закрепилось звание кинематографического часового бренда. За это время часы компании стали героями более 
                    чем 500 художественных и телевизионных фильмов.
                </p>
                <h4 style={{textAlign: 'center', color: 'red'}}>Товар комиссионный поэтому могут быть дефекты, соответственно товар со скидкой</h4>
                <h4 style={{textAlign: 'center', color: 'red'}}>В случае возникновения вопросов или проблем с поиском нужной продукции свяжитесь с нами!</h4>
                <Row style={{marginLeft: '10px', marginRight: '10px', marginBottom: '20px', marginTop: '10px'}}>
                    <Col md={8}>
                        <img src={ hamilton1 } alt="Купить часы Hamilton в Алматы" width='100%' style={{marginBottom: 10}}/>
                    </Col>
                    <Col md={4}>
                        <img src={ hamilton2 } alt="Купить наручные часы Hamilton в Алматы" width='100%'/>
                    </Col>
                </Row>
                <a href='/shop?brand=21'>
                    <img class='scalecat' src={ go } alt="Купить наручные часы Hamiltonx в Алматы" 
                        style={{width: '12em', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }} 
                    />
                </a>
            </Card>
        </Container>
    )
}

export default AK